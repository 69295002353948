<template>
  <div class="pdf">
    <div class="pdf__header  d-flex">
      <div class="col-3 pr-2">
        10062 190th place, Suite 205 Mokena, IL, 60448
        Best Med, Inc
      </div>
      <div class="col-3 pr-2 flex d-flex flex-column">
        <b>Buyer</b>
        10062 190th place, Suite 205 Mokena, IL, 60448
        Best Med, Inc
      </div>
      <div class="col-3 pr-2 d-flex flex-column">
        <b>Seller</b>
        LIBERTY CHEMISTS INC 110-22 LIBERTY AVE RICHMOND HILL, NY 11419
      </div>
      <div class="col-3 pr-2 d-flex flex-column">
        <b>Tracking No#:</b>
        <p>286567782725</p>
        <b>Order No#:</b>
        <p>32981</p>
      </div>
    </div>
    <table class="pdf__table">
      <tr>
        <th>Name</th>
        <th>ndc</th>
        <th>STRENGTH</th>
        <th>Manufacturer</th>
        <th>EXP</th>
        <th>LOT</th>
        <th>PACK COND</th>
        <th>PACK QTY</th>
        <th>PACK PRICE</th>
        <th>QTY</th>
        <th>TOTAL</th>
      </tr>
      <tr>
        <td>Exelon Transdermal Patch, extended release</td>
        <td>00078- 0502- 15</td>
        <td>9.5 MG</td>
        <td>Novartis Pharmaceuticals Corporation</td>
        <td>E12/2023XP</td>
        <td>ALT307240A</td>
        <td>Sealed Original Container</td>
        <td>full</td>
        <td>$600</td>
        <td>2</td>
        <td>$1200</td>
      </tr>
    </table>
    <div class="pdf__info">
     <p> <b>BUYER MEMO:</b> Upon receipt of delivery go to Rxeed.com immediately and confirm your order.</p>
      <p><b>PAYMENT METHOD:</b> ACH, Account Number Ending.</p>
    </div>
    <div class="pdf__text">
      <h2>SHIPPING DISCLAIMER/CHECKLIST:</h2>
      <h3>FROZEN/REFRIGERATED ITEMS MUST BE SHIPPED MON-THU VIA FedEx PRIORITY OVERNIGHT</h3>
      <b>If you are shipping a frozen or refrigerated item(s), remember to:</b>
      Refrigerate/freeze products prior to packaging per manufacturer's guidelines. Pre-cool an expanded polystyrene (EPS) container.
      Double bag items if shipment contains liquid or perishables that might melt or thaw. Arrange items compactly, but leave space around the items for coolant/dry ice. Seal properly
      <h3>FOR ALL SHIPMENTS:</h3>
      <b>SHIPPING AGREEMENT:</b> Shipping items must comply with the Prescription Drug Marketing Act (PDMA), the 2013 Drug Supply Chain Security Act (DSCSA), other laws and Sellers policies. The pharmacist whose signature appears on the signature block represents and warrants he/she is a representative or the member shown and duly authorized to certify that all salable goods shown: have been stored and handled under manufacturers temperature and storage requirements while in members possession, other than information provided, has not been otherwise damaged and, to the best of his/her knowledge, are salable in accordance with applicable laws and regulations, and were not dispensed or otherwise sold by member or transferred to member from another location. Furthermore, contents and quantity of the prescription drugs agree with this form. Final credit amount may be changed to reflect goods that are damaged or missing or do not conform with Rxeed LLC (DBA Rxeed.com) shipping policy.
      <b> DISCLAIMER:</b> You agree to indemnify and hold Rxeed LLC (DBA Rxeed.com) safe from any claim asserted by a third party that involves, relates to, or concerns any of your actions or omissions on this order, including but not limited to your breach of the User Agreement, or your violation of any law or the rights of a third party. When shipping your prescription drug(s), it is your responsibility to adhere to all applicable local, state, and federal laws, as well as statutes and regulations and the payment of any taxes.Per Rxeed.com User Agreement, buyer attests that items are for a specific patient need or purchased during a declared public health emergency. Questions regarding your order, please contact customer service at Info@Rxeed.com
      <h3>Please sign and date Packing Checklist:</h3>

     <ul>
       <li>--- This is the exact prescription Drug(s) Ordered</li>
       <li>
         --- This is the actual quantity order for each item</li>
       <li>
         --- Item(s) is(are) frozen or refrigerated</li>
       <li>
         --- Lot Number, NDC, Expiration Date are legible and visible.</li>
       <li>
         --- Please see tracking information for Shipping date and Delivery date.
       </li>
     </ul>
      Buyer and seller are advised to document Serial Number for each Bottle if provided on the back of the Paper Purchase Order for Record keeping.
    </div>

  </div>
</template>
<script>
export default {
  data(){
    return{

    }
  },

}
</script>
<style lang="scss" scoped>
@import "../../assets/styles/styles";
.pdf{
  border: 15px solid #cccccc;
  padding: 0;
  &__header{
    border: 1px solid grey;
    padding: 15px;
  }
  &__table{
    th{
      background-color: #cccccc;
      padding: 5px;
      border: 1px solid grey;
      max-width: 100%;
    }
    td{
      padding: 10px;
      border: 1px solid grey;
      white-space: pre-wrap;
    }
  }
  &__info{
    background-color: #cccccc;
    padding: 15px 0;
  }
  &__text{
    padding: 20px 10px;
    h2{
      font-size: 15px;
    }
    h3{
      font-size: 14px;
      background-color: grey;
      color: white;
      padding: 5px;
    }
  }
}
</style>